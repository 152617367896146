.for-box-f {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 40px;
    margin-left: 30px
}

.for-box-f1 {
    display: flex;
    align-items: center;
    gap: 4rem;
    margin-left: 30px
}

.base {
    margin-top: 40px;
}

.cont-no {
    display: flex;
    align-items: center;
    gap: 5rem;
    animation: scroll 25s linear infinite;
}

.cont-no:hover {
    animation-play-state: paused;
}

.dip-f {
    display: flex;
    align-items: center;
}

.sectiontitle12 {
    text-align: center;
}

.Brief-h {
    font-size: 28px;
}

.rounded-div1 {
    background: linear-gradient(180deg, #0652dd 0, rgba(217, 217, 217, 0) 100%);
    opacity: .1;
    border-radius: 100px;
    margin-right: -40px;
    width: 76px;
    height: 76px;
}


.AWARDS-btn {
    width: 220px;
    height: 45px;
    border-radius: 70px;
    border: 0.1px solid #0652dd;
    cursor: pointer;
    transition: all 0.25s;
    background-color: #0652dd;
    color: #fff;
}

.AWARDS-btn:hover {
    background-color: #ffffff;
    color: #000000;
}


.cal-for-img {
    width: 600px;
    overflow: hidden;
}

.bedgeimage-12 {
    width: 130px;
    height: 90px;
    color: rgba(0, 0, 0, 0.4);
    transition: 0.1s;
    animation: scroll 20s linear infinite;
}

.button-1-op {
    width: 220px;
    height: 42px;
    border-radius: 100px;
    background-color: #0652dd;
    border: 1px solid #0652dd;
    color: #fff;
    margin: 40px 840px;
    padding: 10px 20px;
    font-size: 15px;
    text-transform: uppercase;
    display: inline-block;
    transition: all 0.10s;
}

.button-1-op:hover {
    background-color: #fff;
    color: #0652dd;
    cursor: pointer;
}

.selection-120 {
    background-color: #151b40;
    width: 1903px;
    height: 581px;
    margin-top: 10px;
}