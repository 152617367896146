.faqs .faqq {
    width: 90%; /* Use a percentage width for responsiveness */
    max-width: 1292px; /* Maximum width for larger screens */
    margin: auto;
    margin-top: 15px;
    padding: 15px;
    background: #000000;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(8, 223, 248, 1), rgba(184, 17, 229, 1)) 1;
}

.faqs .faqq .faqq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
    .faqs .faqq .faqq-question {
        font-size: 18px; /* Smaller font size on smaller screens */
    }

    .faqs .faqq .faqq-question::after {
        font-size: 24px; /* Smaller plus/minus icon */
        width: 26px; /* Adjusted width */
        height: 26px; /* Adjusted height */
    }
}

.faqs .faqq .faqq-question::after {
    content: "+";
    font-size: 30px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid black;
    position: absolute;
    top: 55%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    padding: 4px;
    padding-bottom: 8px;
}

.faqs .faqq .faqq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
}

.faqs .faqq.open .faqq-question {
    margin-bottom: 15px;
}

.faqs .faqq.open .faqq-question::after {
    content: "-";
    justify-content: center;
    align-items: center;
}

.faqs .faqq.open .faqq-answer {
    max-height: 1000px;
    opacity: 1;
}
