.dark-mode {
    background-color: #000000;
    color: #ffffff;
}

.dark-mode .header {
    background-color: #000000;
    border-color: #000000;
}

.dark-mode .dropbtn {
    color: #ffffff;
}
@media (min-width: 320px) {
    .dark-mode .dropbtn {
        color: #000000;
    }
  }
@media (min-width: 768px) {
    .dark-mode .dropbtn {
        color: #000000;
    }
  }
  @media (min-width: 1024px) {
    .dark-mode .dropbtn {
        color: #ffffff;
    }
  }
.dark-mode .dropdown-content1 {
    background-color: #ffffff;
}

.dark-mode a {
    color: #808080;
}

.dark-mode a:hover {
    color: #0652dd;
}
