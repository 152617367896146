 .faqs .faq {
     width: 700px;
     margin: auto;
     margin-top: 15px;
     padding: 15px;
     background: #fff;
     border-radius: 10px;
     box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
 }
 @media (max-width: 320px) {
    .faqs .faq {
        width: 200px;
        margin: auto;
        margin-top: 15px;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }
  @media (max-width: 375px) {
    .faqs .faq {
        width: 300px;
        margin: auto;
        margin-top: 15px;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }
  @media (max-width: 425px) {
    .faqs .faq {
        width: 300px;
        margin: auto;
        margin-top: 15px;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }
  /* Medium screens */
  @media (min-width: 768px) {
    .faqs .faq {
        width: 400px;
        margin: auto;
        margin-top: 15px;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }

  @media (min-width: 1024px) {
    .faqs .faq {
        width: 500px;
        margin: auto;
        margin-top: 15px;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }
  @media (min-width: 1440px) {
    .faqs .faq {
        width: 700px;
        margin: auto;
        margin-top: 15px;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }
 .faqs .faq .faq-question {
     position: relative;
     font-size: 20px;
     padding-right: 80px;
     transition: all 0.4s ease;
 }
 /* Small screens */
@media (max-width: 320px) {
    .faqs .faq .faq-question {
        position: relative;
        font-size: 18px;
        padding-right: 80px;
        transition: all 0.4s ease;
    }
  }
  
  /* Medium screens */
  @media (min-width: 768px) {
    .faqs .faq .faq-question {
        position: relative;
        font-size: 20px;
        padding-right: 80px;
        transition: all 0.4s ease;
    }
  }

  @media (min-width: 1024px) {
    .faqs .faq .faq-question {
        position: relative;
        font-size: 20px;
        padding-right: 80px;
        transition: all 0.4s ease;
    }
  }
  

 .faqs .faq .faq-question::after {
     content: "+";
     font-size: 30px;
     text-align: center;
     border-radius: 50%;
     border: 2px solid black;
     position: absolute;
     top: 55%;
     right: 0px;
     transform: translateY(-50%);
     width: 30px;
     height: 30px;
     transition: all 0.2s ease;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 4px;
     padding-bottom: 8px;
 }

 .faqs .faq .faq-answer {
     opacity: 0;
     max-height: 0;
     overflow: hidden;
     transition: all 0.2s ease;
 }

 .faqs .faq.open .faq-question {
     margin-bottom: 15px;
 }

 .faqs .faq.open .faq-question::after {
     content: "-";
     justify-content: center;
     align-items: center;
     /* padding: 5px; */
 }

 .faqs .faq.open .faq-answer {
     max-height: 1000px;
     opacity: 1;
 }