.Find-col {
    font-size: 42px;
}

.Your-col {
    color: #0652dd;
}

.are-para {
    font-size: 18px;
    margin: 30px 0px;
}

.btn-open {
    width: 200px;
    height: 42px;
    border-radius: 100px;
    background-color: #0652dd;
    border: 1px solid #0652dd;
    color: #fff;
    padding: 10px 20px;
    font-size: 15px;
    text-transform: uppercase;
    display: inline-block;
    transition: all 0.20s;
}

.btn-open:hover {
    background-color: #fff;
    color: #0652dd;
    cursor: pointer;
}

.com-im {
    width: 55px;
    height: 55px;
}

.depy-ul {
    display: flex;
    gap: 13rem;
    padding-left: 20px;
    align-items: center;
    padding-top: 10px;
}

.depy-ul12 {
    display: flex;
    gap: 12.3rem;
    padding-left: 20px;
    align-items: center;
    padding-top: 10px;
}

.Positions-1 {
    color: #0652dd;
}

.cl-box {
    display: flex;
    gap: 4rem;
    justify-content: center;
    margin-top: 50px;
    align-items: center;
}

.can-fit {
    font-size: 18px;
    line-height: 30px;
}

.fot-12 {
    background-color: #151b40;
    width: 1903px;
    height: 908px;
    margin: auto;
    color: #fff;
}

.for-care {
    display: flex;
}

.fa-check {
    background: #b0cbfd;
    border-radius: 50%;
    width: 30px;
    height: 25px;
    margin-right: 10px;
    color: rgb(52, 74, 240);

    padding-top: 3px;

    text-align: center;
}

.tag-1 {
    width: 300px;
    height: 230px;
}

.form-fl {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-top: 40px;
}

.form-apply {
    width: 660px;
    height: 840px;
    background-color: #fff;
    color: black;
}

.App-12 {
    text-align: center;
}

.input-fl {
    display: flex;
}

.relocate-1 {
    padding-left: 30px;
}

.wefc-select {
    font-size: 17px;
    color: #000000;
    width: 600px;
    height: 38px;
    border: 0px solid rgb(84, 173, 233);
    border-bottom: 0.1px solid black;
    padding-left: 20px;
    margin: 0px 29px;
}

.wpcf7-form-control {
    font-size: 17px;
    color: #000000;
    width: 600px;
    height: 38px;
    border: 0px solid rgb(84, 173, 233);
    border-bottom: 0.1px solid black;
    padding-left: 20px;
    margin: 1px 29px;
}

.inp-n0 {
    font-size: 17px;
    color: #000000;
    width: 600px;
    height: 38px;
    border: 0px solid rgb(84, 173, 233);
    border-bottom: 0.1px solid black;
    padding-left: 20px;
    margin: 12px 29px;
}

.inp-n10 {
    font-size: 17px;
    color: #000000;
    width: 600px;
    height: 78px;
    border: 1px solid rgb(0, 0, 0);
    border-bottom: 0.1px solid black;
    padding-left: 20px;
    margin: -10px 29px;
}

.sub-ty-1 {
    width: 170px;
    height: 55px;
    background-color: #0652dd;
    color: #ffffff;
    border: 0.5px solid #0652dd;
    border-radius: 30px !important;
    font-size: 20px;
    text-transform: uppercase;
    margin: 40px 250px;
}