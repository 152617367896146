
  
  .logo {
    height: 50px;
    width: 150px;
  }
  
  /* .fiv-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14rem;
    margin-top: 20px;
  } */
  
  .company {
    margin: 0 5px !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .06em;
    text-transform: uppercase;
    color: #1c1c1c !important;
    margin: 0 25px;
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
  }
  
  .company:hover {
    color: #0652dd;
  }
  
  .fiv {
    gap: 0.1rem;
    display: flex;
  }
  
  /* .fiv-2 {
    display: flex;
    gap: 1rem;
  } */
  
  .down {
    font-size: 12px;
    padding-top: 2px;
    color: black;
  }
  
  /* .btn {
    width: 157.42px;
    height: 40px;
    border-radius: 70px;
    border: 2px solid #00008b;
    cursor: pointer;
    transition: all 0.10s;
  }
  
  .btn:hover {
    color: #0652dd;
  } */
  
  .btn1 {
    width: 140px;
    height: 40px;
    background-color: #0652dd;
    color: #ffffff;
    border-radius: 70px;
    border: 0.5px solid #0652dd;
    cursor: pointer;
    transition: all 0.10s;
  }
  
  .btn1:hover {
    background-color: #fff;
    color: #0652dd;
  }
  
  /* .homebanner {
    background-color: #fFFF;
    margin: auto;
    padding: 2px 20px 0 20px;
    border-radius: 25px;
    width: 1320px;
    height: 500px;
    margin-top: 20px;
    display: flex;
  } */
  
  .dot {
    padding-left: 50px;
    padding-top: 40px;
    font-size: 45px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  
  /* .span {
    color: #0652dd;
  } */
  
  .text-1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 22px;
    padding-left: 50px;
    padding-top: 6px;
  }
  
  .btn-01 {
    width: 135px;
    height: 45px;
    background-color: #0652dd;
    color: #ffffff;
    border-radius: 70px;
    border: 0.5px solid #0652dd;
    cursor: pointer;
    transition: all 0.10s;
  }
  
  .btn-01:hover {
    background-color: #fff;
    color: #0652dd;
  }
  
  .logo-button {
    display: flex;
    align-items: center;
    margin-left: 50px;
    gap: 1.5rem;
  }
  
  .logo1 {
    width: 80px;
    height: 80px;
  }  
  .img {
    display: flex;
    gap: 2rem;
    margin-left: 55px;
  }
  
  /* .row {
    display: flex;
    gap: 5rem;
    justify-content: center;
    align-items: center;
  } */
  
  .Over {
    margin-top: 40px;
    text-align: center;
    font-size: 22px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  
  /* .Over1 {
    color: #0652dd;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  } */
  
  .log {
    display: flex;
    gap: 3rem;
    align-items: center;
    margin-left: 300px;
  }
  
  .logb {
    display: flex;
    gap: 3rem;
    align-items: center;
    margin-left: 300px;
  }
  
  .logc {
    display: flex;
    gap: 3rem;
    align-items: center;
    margin-left: 300px;
    margin-left: 390px;
  }
  
  .img-5 {
    display: flex;
    gap: 6rem;
  }
  
  .img-1 {
    display: flex;
    gap: 4rem;
    margin-left: 12px;
  }
  
  .log-1 {
    width: 62px;
    height: 65px;
    cursor: pointer;
  }
  
  /* .log-2 {
    width: 146px;
    height: 37px;
    cursor: pointer;
  } */
  

  
  .log-5 {
    width: 122.63px;
    height: 24.36px;
    cursor: pointer;
  }
  
  .log-5-1 {
    width: 152.63px;
    height: 100.36px;
    margin: auto;
    cursor: pointer;
  }
  
  .log-6 {
    width: 122.63px;
    height: 28.92px;
    cursor: pointer;
  }
  
  .log-7 {
    width: 122.63px;
    height: 27.31px;
    cursor: pointer;
  }

  
  .log-9 {
    width: 83px;
    height: 23px;
    cursor: pointer;
  }
  

  
  .log-11 {
    width: 47px;
    height: 47px;
    padding-left: 20px;
    cursor: pointer;
  }
  
  .log-12 {
    width: 47px;
    height: 47px;
    cursor: pointer;
  }
  
  .log-13 {
    width: 117px;
    height: 24px;
    cursor: pointer;
  }
  
  .log-14 {
    width: 122.63px;
    height: 21.81px;
    cursor: pointer;
  }
  
  .log-15 {
    width: 89px;
    height: 23px;
    cursor: pointer;
  }
  
  .log-16 {
    width: 116px;
    height: 24px;
    cursor: pointer;
  }
  
  .log-17 {
    width: 109px;
    height: 20px;
    cursor: pointer;
  }
  
  .log-18 {
    width: 54px;
    height: 39px;
    cursor: pointer;
  }
  
  .log-19 {
    width: 118px;
    height: 20px;
    cursor: pointer;
  }
  
  .log-20 {
    width: 99px;
    height: 23px;
    cursor: pointer;
  }
  
  .log-21 {
    width: 54px;
    height: 31px;
    cursor: pointer;
  }
  
  .log-22 {
    width: 119px;
    height: 14px;
    cursor: pointer;
  }
  
  .log-23 {
    width: 119px;
    height: 23px;
    cursor: pointer;
  }
  
  .home_about {
    margin-top: 40px;
    width: 1903px;
    /* height: 600px; */
    background-color: #fff;
    padding: 50px 0;
    padding-bottom: 0;
  }
  
  .all-section {
    margin-left: 300px;
  }
  
  /* .section {
    font-size: 40px;
    color: #0652dd;
  } */
  
  /* .head {
    color: black;
  } */
  
  .p {
    font-size: 18px;
  }
  
  /* .container {
    display: flex;
    gap: 1rem;
  } */
  
  
  .com-pol {
    width: 40px;
    height: 41px;
  }
  
  .ty-91 {
    font-size: 20px;
    color: #0652dd;
  }
  
  .for-f {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  
  
  /* .services {
    font-size: 40px;
    text-align: center;
    margin-top: 40px;
  } */
  
  /* .services1 {
    color: #0652dd;
  } */
  
  /* .doc-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  } */
  
  /* .box {
    width: 300px;
    height: 330px;
    background-color: #fff;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.10s;
  } */
  
  /* .box:hover {
    background-color: #0652dd;
    color: #fff;
  } */
  
  .img-fluid {
    padding-left: 10px;
    padding-top: 0px;
  }
  
  /* .lazy {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  } */
  
  /* .modile {
    font-size: 22px;
  } */
  
  /* .we {
    font-size: 18px;
    text-align: justify;
    padding-left: 15px;
  } */
  
  /* .btn5 {
    width: 165px;
    height: 55px;
    border-radius: 70px;
    border: 1px solid #0652dd;
    cursor: pointer;
    font-size: 16px;
    color: #0652dd;
    margin-top: 40px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    gap: 1rem;
  } */
  
  /* .btn5-1 {
    font-size: 20px;
  } */
  
  /* .box-1 {
    width: 1903px;
    height: 500px;
    background-color: #fff;
    margin-top: 60px;
    text-align: center;
  } */
  
  /* .spck {
    color: #0652dd;
    padding-top: 45px;
    font-size: 40px;
  } */
  
  /* .spck1 {
    color: black;
    font-size: 40px;
  } */
  
  /* .sp-1 {
    font-size: 18px;
    margin: 0px 0px 16px;
  } */
  
  .sliders {
    display: flex;
    gap: 1rem;
  }
  
  .slider {
    height: 340px;
    border-radius: 20px;
    background-color: #fff;
  }
  
  
  .col-9 {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  /* .img-Healthcare {
    padding-left: 20px;
    padding-top: 20px;
  } */
  
  .as-2 {
    padding-left: 25px;
    font-size: 18px;
  }
  
  .sliders-1 {
    display: flex;
    align-items: center;
    animation: reverse scroll 20s linear infinite;
  }
  
  /* .slider {
    cursor: pointer;
  } */
  
  @keyframes scroll {
    from {
      left: translateX(0);
    }
  
    to {
      transform: translateX(-100%);
    }
  }
  
  
  
  .docb {
    width: 1200px;
    /* overflow: hidden; */
    /* margin: auto; */
    /* user-select: none; */
  }
  
  .docb1 {
    width: 1200px;
    height: 444px;
    overflow: hidden;
    margin: auto;
    user-select: none;
  }
  
  .slider1 {
    height: 600px;
    margin: auto;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  
  
  .slide-track {
    display: flex;
    width: calc(250px * 18);
  
  }
  
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(calc(-250px * 9));
    }
  }
  
  
  .slide {
  
    display: flex;
    align-items: center;
    animation: reverse scroll 20s linear infinite;
    cursor: pointer;
  }
  
  .slide01 {
    display: flex;
    align-items: center;
    animation: scroll 20s linear infinite;
    cursor: pointer;
  }

  .Create {
    color: black;
  }
  
  .mobile {
    text-align: center;
  }
  
  .banner {
    border-radius: 25px;
  
  }

  .site-for {
    display: flex;
    align-items: center;
    gap: 4rem;
    padding-left: 40px;
  }
  
  .Million-app {
    color: #ffde91;
    padding-left: 680px;
  
  }
  
  .Apna {
    padding-left: 680px;
    padding-top: 250px;
    color: #fff;
  }
  
  .regional {
    padding-left: 680px;
    color: #fff;
    font-size: 18px;
  }
  
  .play-button1 {
    display: flex;
    gap: 1rem;
  }
  
  .btn-Case1 {
    width: 204px;
    height: 47px;
    border-radius: 70px;
    border: 2px solid #ffffff;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 20px;
    gap: 0.5rem;
    margin-left: 680px;
    background-color: #230C26;
    margin-bottom: 50px;
    transition: all 0.10s;
  }
  
  .btn-Case1:hover {
    background-color: #fff;
    color: black;
  }
  
  .site {
    padding-left: 640px;
  }
  
  .add {
    color: #51b88f;
    font-size: 26px;
  }
  
  .add-1 {
    color: #fff;
    font-size: 12px;
  }
  
  .fund-count1 {
    color: #51b88f;
    font-size: 26px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  
  .Rating1 {
    color: #ffffff;
    font-size: 12px;
  }
  
  .our {
    font-size: 18px;
  }
  
  .help {
    border-radius: 50px;
    width: 70px;
    height: 70px;
  }
  
  .fkt {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .warnings {
    width: 1242px;
    height: auto;
    padding: 20px;
    margin: auto;
    margin-top: 40px;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0;
  }

  
  .lo3 {
    width: 250px;
    height: 40px;
    cursor: pointer;
    padding-left: 800px;
    padding-top: 150px;
  }
  
  .banksathi-content {
    font-size: 18px;
    padding-left: 800px;
  }
  
  .inline-row {
    display: flex;
    gap: 5rem;
  }
  
  .fund-count {
    font-weight: 500;
    font-size: 36px;
    color: #095738;
    line-height: 46px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  
  .star {
    color: #fff;
    font-size: 10px;
  }
  
  .Rating {
    color: #095738;
  
    font-size: 12px;
  }
  
  .fund {
    font-weight: 500;
    font-size: 35px;
    color: #095738;
    line-height: 46px;
  }
  
  .Rat-1 {
    font-size: 12px;
    color: #095738;
    line-height: 40px;
  }
  
  .col-md-4 {
    padding-left: 800px;
    margin-top: 0px;
  }
  
  .col-sm-6 {
    margin-top: 10px;
  }
  
  .downloads {
    padding-left: 800px;
  }
  
  .btn-Case {
    width: 204px;
    height: 47px;
    border-radius: 70px;
    border: 2px solid #063d27;
    cursor: pointer;
    font-size: 16px;
    color: #063d27;
    display: flex;
    align-items: center;
    padding-left: 20px;
    gap: 0.5rem;
    margin-left: 800px;
    background-color: #9adcc1;
    transition: all 0.10s;
  }
  
  .btn-Case:hover {
    background-color: #fff;
  }
  
  .b-1 {
    border-radius: 50%;
    font-size: 30px;
    color: #00b793;
  }
  
  .play-button {
    display: flex;
    gap: 1rem;
  }
  
  .google-play {
    width: 124px;
    cursor: pointer;
    height: 36px;
  }
  
  .l3 {
    margin-top: 40px;
  }
  
  /* .my-2 {
    font-size: 18px;
  } */
  
  .img-fluid {
    border-radius: 50px;
    width: 70px;
    height: 70px;
    margin-left: 0px;
  }
  
  
  .lazyloaded {
    margin-top: 85px;
    margin-left: 100px;
    width: 400px;
    height: 350px;
  }
  
  .kovs {
    width: 200px;
    height: 80px;
  }
  
  .fashion {
    font-size: 18px;
  }
  
  .btn-Caser {
    width: 204px;
    height: 47px;
    border-radius: 70px;
    border: 1px solid #000000;
    cursor: pointer;
    font-size: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    padding-left: 20px;
    gap: 0.5rem;
  
    background-color: #ffffff;
    ;
  }
  
  .bfor1 {
    border-radius: 50%;
    font-size: 30px;
    color: #b2b2b2;
  }
  
  .koovs_mobi_image {
    display: flex;
    align-items: center;
    gap: 6rem;
    padding-top: 70px
  }
  
  .img-fluid1 {
    padding-top: 85px;
  }

  .assurecare_image {
    width: 500px;
    height: 359px;
    padding-left: 50px;
  }
  
  .align {
    display: flex;
    gap: 4rem;
    padding-top: 150px;
  }
  
  .p-21 {
    font-size: 18px;
    color: #212529;
  }
  
  h-3 {
    font-size: 20px;
    color: #5DAC4C;
  }
  
  .btn-Case21 {
    width: 204px;
    height: 47px;
    border-radius: 70px;
    border: 2px solid #ffffff;
    cursor: pointer;
    font-size: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    padding-left: 20px;
    gap: 0.5rem;
    background-color: #b4ffe3;
    margin-bottom: 50px;
    transition: all 0.10s;
  }
  
  .btn-Case21:hover {
    background-color: #fff;
    color: black;
  }
  
  .img-10 {
    margin: auto;
    height: 50px;
    width: 248px;
    padding: 10px;
  }
  
  
  .button-1 {
    width: 220px;
    height: 42px;
    border-radius: 100px;
    background-color: #0652dd;
    border: 1px solid #0652dd;
    color: #fff;
    margin: 40px 840px;
    padding: 10px 20px;
    font-size: 15px;
    text-transform: uppercase;
    display: inline-block;
    transition: all 0.10s;
  }
  
  .button-1:hover {
    background-color: #fff;
    color: #0652dd;
    cursor: pointer;
  }
  
  .fot-op {
    width: 1280px;
    margin: auto;
    background-color: #fff;
    padding: 0px 12px;
    border-radius: 15px
  }
  
  .honk-lk {
    font-size: 40px;
    margin: 0px 0px 8px;
    color: #212529;
    text-align: center;
    padding: 15px;
  }
  
  .honk-lk1 {
    color: #0652dd;
  }
  
  .hont-1 {
    font-size: 18px;
    margin: 0px 0px 16px;
    color: #212529;
    text-align: center;
  }
  

  
  .for-het {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    gap: 4rem;
  }
  
  .h1-1 {
    color: #0652dd;
    font-size: 40px;
  }
  
  .h1-1-span {
    color: #000000;
  }
  
  .for-conttact {
    width: 140px;
    height: 40px;
    background-color: #0652dd;
    color: #ffffff;
    border-radius: 70px;
    border: 0.5px solid #0652dd;
    cursor: pointer;
    transition: all 0.10s;
  }
  
  .for-conttact:hover {
    background-color: #fff;
    color: #0652dd;
  }
  
  .clock-1 {
    width: 624px;
    height: 75px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 18rem;
    border-radius: 20px;
    justify-content: center;
    cursor: pointer;
    border: 0px solid #fff;
  }
  
  .hop-ty {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .hop-ty1 {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-right: 50px;
  }
  
  .hop-ty2 {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-right: 10px;
  }
  
  .hop-ty3 {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-right: 30px;
  }
  
  .clock-1-1 {
    width: 50px;
    height: 50px;
  }
  
  .clk-1 {
    font-size: 18px;
  }
  
  .clock-1-2 {
    font-size: 38px;
    cursor: pointer;
  }
  
  .clock-10-1 {
    width: 50px;
    height: 50px;
  }
  
  .blurt {
    color: #0652dd;
  }
  
  
  .roll-iol {
    width: 451px;
    height: 150px;
    background-color: rgb(255, 246, 215);
  }
  
  .roll-iol-6 {
    width: 451px;
    height: 150px;
    background-color: rgb(242, 255, 227);
  }
  
  .i-opy {
    width: 60px;
    height: auto;
    padding: 20px;
  }
  
  .i-opy {
    width: 60px;
    height: auto;
    padding: 20px;
  }
  
  .i-op {
    width: 99px;
    height: 50px;
    padding-top: 30px;
  }
  
  .Python {
    font-size: 18px;
    margin-top: 0px;
  }
  
  .Python1 {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .roll-iol-1 {
    width: 451px;
    height: 150px;
    background-color: rgb(221, 248, 254);
  }
  
  .roll-iol2 {
    width: 451px;
    height: 150px;
    background-color: rgb(231, 236, 240);
  }
  
  .roll-iol-2 {
    width: 451px;
    height: 150px;
    background-color: rgb(217, 228, 238);
  }
  
  .roll-iol-3 {
    width: 451px;
    height: 150px;
    background-color: rgb(255, 211, 212);
  }
  
  .roll-iol-4 {
    width: 451px;
    height: 150px;
    background-color: rgb(245, 218, 239);
  }
  
  .roll-iol-5 {
    width: 451px;
    height: 150px;
    background-color: rgb(221, 247, 230);
  }
  
  
  .dsp_content_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
  }
  
  .timeline-content1 {
    background-color: #fff;
    width: 230px;
    height: 75px;
    box-shadow: rgba(100, 100, 111, .2) 0 5px 10px 0;
    border-radius: 7px;
    margin-top: 20px;
  }
  
  .timeline-content2 {
    background-color: #fff;
    width: 210px;
    height: 75px;
    box-shadow: rgba(100, 100, 111, .2) 0 5px 10px 0;
    border-radius: 7px;
    margin-top: 20px;
  }
  
  .timeline-content3 {
    background-color: #fff;
    width: 190px;
    height: 75px;
    box-shadow: rgba(100, 100, 111, .2) 0 5px 10px 0;
    border-radius: 7px;
    margin-top: 20px;
  }
  
  .timeline-content4 {
    background-color: #fff;
    width: 200px;
    height: 75px;
    box-shadow: rgba(100, 100, 111, .2) 0 5px 10px 0;
    border-radius: 7px;
    margin-top: 20px;
  }
  
  .io {
    width: 40px;
    height: 41px;
  
  }
  
  .timeline-b1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-left: 0px;
    margin-top: 30px;
  }
  
  .timeline-b2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 450px;
    gap: 1rem;
    margin-top: 30px;
  }
  
  .blue-mb-1 {
    font-size: 20px;
  }
  
  .normal_content {
    font-size: 17px;
  }
 
  
  
  
  
  /* body {
    font-family: Arial, Helvetica, sans-serif;
  } */
  
  .navbar {
    overflow: hidden;
  }
  
  .navbar a {
    float: left;
    font-size: 16px;
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* .dropdown {
    float: left;
    overflow: hidden;
  } */
  
  /* .dropdown .dropbtn {
    font-size: 15px;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  } */
  
  .navbar a:hover,
  .dropdown:hover .dropbtn {
    color: #0652dd;
  }
  
  /* .dropbtn {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  } */
  
  /* .right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    margin-left: 5px;
  } */
  
  .dropdown-content1 {
    box-shadow: 0 30px 80px rgb(0 0 0 / 14%);
  }
  
  /* .dropdown-content1 a {
    float: none;
    color: black;
    padding: 9px 14px;
    text-decoration: none;
    display: block;
    text-align: left;
  } */
  
  /* .dropdown-content1 a:hover {
    background-color: #0652dd;
    color: #fff;
    border-radius: 40px;
    transition: all 0.10s;
  } */
  
  .dropdown:hover .dropdown-content1 {
    display: flex;
  }
  
  
  /* .selection-typ1 {
    display: flex;
  } */
  
  /* .dropdown-content2 {
    display: none;
    position: absolute;
    background-color: #fff;
    width: max-content;
    height: auto;
    min-width: 160px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 4px;
    z-index: 1;
  } */
  
  /* .dropdown-content2 a {
    float: none;
    color: black;
    padding: 9px 14px;
    text-decoration: none;
    display: block;
    text-align: right;
  } */
  
  /* .dropdown-content2 a:hover {
    background-color: #0652dd;
    color: #fff;
    border-radius: 40px;
    transition: all 0.10s;
  } */
  
  /* .dropdown:hover .dropdown-content2 {
    display: block;
  } */
  
  
  
  /* .dropdown-content3 {
    display: none;
    position: absolute;
    background-color: #fff;
    width: max-content;
    height: auto;
    min-width: 160px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 4px;
    z-index: 1;
  } */
  
  /* .dropdown-content3 a {
    float: none;
    color: black;
    padding: 9px 14px;
    text-decoration: none;
    display: block;
    text-align: left;
  } */
  
  /* .dropdown-content3 a:hover {
    background-color: #0652dd;
    color: #fff;
    border-radius: 40px;
    transition: all 0.10s;
  } */
  
  /* .dropdown:hover .dropdown-content3 {
    display: block;
  } */
  
  
  .dropdown-content4 {
    display: none;
    position: absolute;
    background-color: #fff;
    width: max-content;
    height: auto;
    padding: 10px;
    min-width: 160px;
    border-radius: 15px;
    box-shadow: 0 0 4px;
    z-index: 1;
  }
  
  .dropdown-content4 a {
    float: none;
    color: black;
    padding: 9px 14px;
    text-decoration: none;
    /* display: block; */
    text-align: left;
  }
  
  .dropdown-content4 a:hover {
    background-color: #0652dd;
    color: #fff;
    transition: all 0.10s;
    border-radius: 40px;
  }
  
  .dropdown:hover .dropdown-content4 {
    display: block;
  }
  
  
  
  
  
  /* .dropdown-content6 {
    display: none;
    position: absolute;
    background-color: #fff;
    width: max-content;
    height: auto;
    padding: 10px;
    min-width: 160px;
    border-radius: 15px;
    box-shadow: 0 0 4px;
    z-index: 1;
  }
  
  .dropdown-content6 a {
    float: none;
    color: black;
    padding: 9px 14px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content6 a:hover {
    background-color: #0652dd;
    color: #fff;
    border-radius: 40px;
    transition: all 0.10s;
  }
  
  .dropdown:hover .dropdown-content6 {
    display: block;
  } */
  
  
  
  .for-pop {
    width: 624px;
    height: 95px;
    background-color: #fff;
    border-radius: 15px !important;
    text-align: center;
    background-position: center;
    transform: scale(1) !important;
    unicode-bidi: isolate;
    display: block;
  }
  
  .pop {
    padding: 10px 0;
    font-size: 18px;
  }
  
  
  
  
 
  .for-flex {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  .for-sli {
    width: 404px;
    height: 430px;
    background-color: #fff;
    border-radius: 15px;
    margin: 15px 10px;
    box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0;
    animation: scroll 15s linear infinite;
  }
  
  .for-img-type {
    width: 110px;
    height: 31px;
    padding: 30px 150px;
  }
  
  .for-img-type-01 {
    width: 150px;
    height: 31px;
    padding: 30px 130px;
  }
  
  .for-img-type7 {
    width: 47px;
    height: 47px;
    padding: 10px 170px;
  }
  
  .for-img-type-02 {
    width: 98px;
    height: 31px;
    padding: 30px 150px;
  }
  
  .for-text {
    padding: 5px 30px;
  }
  
  .for-img-f {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 150px 20px;
  }
  
  
  .take-dop {
    color: #dc3545 !important;
  }
  
  .selection-typ {
    background-color: #151b40;
    width: 1903px;
    height: 591px;
    margin-top: 150px;
  }
  
  .type-doc {
    color: #fff;
  }
  
  .type-doc-1 {
    font-size: 32px;
  }
  
  .behind-doc {
    font-size: 18px;
  }
  
  .for-arro {
    padding-left: 160px;
  }
  
  .fooer {
    font-size: 18px
  }
  
  .for-star-12 {
    display: flex;
    gap: 7rem;
    align-items: center;
  }
  
  .for-star1 {
    width: 120px;
    height: 50px;
  }
  
  .for-star2 {
    width: 120px;
    height: 45px;
  }
  
  .for-star3 {
    width: 120px;
    height: 45px;
  }
  
  .for-star4 {
    width: 120px;
    height: 59px;
  }
  
  .for-star5 {
    width: 120px;
    height: 50px;
  }
  
  .form {
    background-color: #fff;
    margin: 20px 0;
    border-radius: 15px;
    width: 624px;
    height: 535px;
  }
  
  .for-to-flex {
    display: flex;
    align-items: center;
    gap: 3rem;
    justify-content: center;
    margin: 0px 350px;
  }
  
  .kol-10 {
    display: flex;
  }
  
  .inp-name {
    font-size: 15px;
    color: #000000;
    /* width: 280px; */
    height: 38px;
    border: 0px solid rgb(84, 173, 233);
    border-bottom: 0.1px solid black;
    padding-left: 20px;
    /* margin: 17px 29px; */
  }
  
  .for-num-o {
    border: 0px solid black;
  }
  
  .country_popuplist {
    border: 0;
    border-bottom: 2px solid #0000004d !important;
    border-radius: 0;
    position: relative;
  }
  
  .form-select {
    width: 280px;
    margin: 20px;
    padding: .35rem 2rem .75rem;
    font-size: 1rem;
    color: #212529;
    padding-left: 20px;
  }
  
  .kol_1 {
    display: flex;
    align-items: center;
  }
  
  .Regenerate-Numbers {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 40px 40px;
  }
  
  .Regenerate {
    width: 55px;
    height: 35px;
  }
  
  .btn-Regenerate {
    border: 0px solid rgb(0, 0, 0);
    width: 128px;
    height: 35px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .Reg-top {
    width: 18px;
    height: 18px;
  }
  
  .repoint {
    font-size: 20px;
  }
  
  .form-control {
    /* width: 588px;
    margin-left: 10px;
    padding-left: 15px; */
    font-size: 17px;
    height: 99px;
    border: 0px solid black;
    border-bottom: 1px solid black;
  }
  
  .lable-con {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: 20px;
  }
  
  .radio-typ {
    width: 19px;
    height: 19px;
  }
  
  .send-btn {
    width: auto;
    height: 40px;
    background-color: #0652dd;
    color: #ffffff;
    border-radius: 70px;
    border: 0.5px solid #0652dd;
    cursor: pointer;
    transition: all 0.10s;
    margin-left: 250px;
  
  }
  
  .ensure {
    text-align: center;
  }
  
  .for {
    display: flex;
  }
  
  .for-box1 {
    width: 475px;
    height: 185px;
    background-color: #0652dd;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  
  .for-box2 {
    width: 475px;
    height: 185px;
    background-color: #1d6af6;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  
  .for-box3 {
    width: 475px;
    height: 185px;
    background-color: #0659f0;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  
  .for-box4 {
    width: 475px;
    height: 185px;
    background-color: #347cff;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  
  .io-op {
    width: 50px;
    height: 50px;
    padding: 0px 200px;
    padding-top: 50px;
  }
  
/*   
  .for-logo-typ {
    background-color: #fff;
    width: 1900px;
    height: 250px;
  }
  
  .for-logo-top {
    width: 1296px;
    height: 153px;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: auto;
    gap: 7rem;
    cursor: pointer;
  }
  

   */
  /* .bedgeimage1:hover {
    filter: saturate(100%);
  }
   */
  .Awards {
    font-size: 18px;
    padding-left: 330px;
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  
  .Awards-p {
    width: 550px;
    height: 1px;
    background-color: #000000;
  }
  
  .call-icon {
    background-color: #000000;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    padding: 5px;
    color: #fff;
    font-size: 16px;
  }
  
  .call-num {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
  
  .for-ion-logo {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    cursor: pointer;
  }
  
  .class-Company {
    font-size: 20px;
  }
  
  .class-botom {
    font-size: 16px;
    cursor: pointer;
  }
  
  .class-botom:hover {
    color: #1d6af6;
  }
  
  .all-for {
    display: flex;
    gap: 6rem;
    align-items: center;
    margin-top: 50px;
    justify-content: center;
  }
  
  .for-contect {
    padding-right: 80px;
  }
  
  /* .view-all {
    width: 130px;
    height: 35px;
    border-radius: 70px;
    border: 1px solid #0652dd;
    cursor: pointer;
    font-size: 16px;
    color: #0652dd;
  
  } */
  
  .line-type {
    background-color: #393838;
    height: 0px;
    width: 1903px;
    margin-top: 30px;
  }
  
  .Pvt-Ltd {
    border-top: 1px solid #ccc;
    font-size: 12px;
    padding: 16px 0;
    text-align: center;
  }

  .bouncing-box {

    margin: auto;
    border-radius: 10px; /* Rounded corners */
    position: relative;
    margin-top: 50px;
    animation: move 4s infinite; /* Continuous movement */
}

@keyframes move {
    0% {
        transform: translateY(0); /* Starting position */
    }
    50% {
        transform: translateY(-100px); /* Move up */
    }
    100% {
        transform: translateY(0); /* Move back down */
    }
}
* {
	margin: 0;
	padding: 0;
}

/* body {
	background: #222222;
	overflow: hidden;
} */

.square {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
	background: linear-gradient(#303030, #757575);
	z-index: 2;
}

.circle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
	background: #1cd99d;
	border-radius: 50%;
}

.triangle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
	background: #f5f5f5;
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
	-webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.f-all-app{
  filter: saturate(0);
}
:hover.f-all-app{
  filter: saturate(100%);
}